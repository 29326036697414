header {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 24px 0;
    .headerLogo {
        img {
            max-width: 77px;
        }
    }
    .headerPropertyLogo{
        img{
            max-width: 123px;
            max-height: 36px;
        }
    }


.searchIcon{
    float: right;    
    .has-feedback {
        position: relative;
    }
    .form-group {
        float: right !important;
        transition: all 0.35s, 
        border-radius 0s;
        width: 100%;
        height: 36px;
        box-shadow: none;
        border-radius: 6px;
        border: none;
        margin-bottom: 0;
        background: #ECF1F5;
      }
      .form-group input.form-control {
        border: 0 none;
        background: transparent;
        box-shadow: none;
        display:block;
        padding: 8px 18px;
        width: 36px;
        font-size: 15px;
        line-height: 18px;
        border: 0.5px solid transparent;
      }
      .form-group.searchshow input.form-control {
        width: 100%;
        background: #fff;
        border-color: rgba(0, 0, 0, 0.1);
      }
      .form-group input.form-control::-webkit-input-placeholder {
        display: none;
        color: rgba(0, 0, 0, 0.3);
        opacity: 1;
      }
      .form-group input.form-control:-moz-placeholder {
        display: none;
        color: rgba(0, 0, 0, 0.3);
        opacity: 1;
      }
      .form-group input.form-control::-moz-placeholder {
        display: none;
        color: rgba(0, 0, 0, 0.3);
        opacity: 1;
      }
      .form-group input.form-control:-ms-input-placeholder {
        display: none;
        color: rgba(0, 0, 0, 0.3);
        opacity: 1;
      }      
    //   .form-group:hover,
    //   .form-group.hover {
    //     width: 100%;
    //     border-radius: 6px;
    //   }
      .form-group span.form-control-feedback {
        position: absolute;
        top: 3px;
        right: -1px;
        z-index: 2;
        display: block;
        width: 36px;
        height: 36px;
        line-height: 30px;
        text-align: center;
        color: #5E636E;
        left: initial;
        font-size: 15px;
      }
}
.searchIcon.searchshowparent{
  width: calc(100% - 61px);
}
.userInfoIcon {
    background: #ECF1F5;
    height: 36px;
    width: 36px;
    border-radius: 6px;
    text-align: center;
    margin-left: 24px;
    .userInfo {
        line-height: 35px;
    }
}
    
      
}