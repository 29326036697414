html {
  --black-color: #000;
  --white-color: #fff ;
  --gray-color: #cfd7db ;
  --color-dark-gray: #5e636e;
  --web-light-gray: #ecf1f5;
  --alpha-70: rgba(0,0,0,0.7);
  --color-blue: #1772ad;
  --web-bg: #f7f8f9;
  --color-white: #ffffff;
  --color-accent: #ff6600;
  --alpha-white-blur: rgba(255, 255, 255, 0.9);
  --icon-gray: rgba(60, 60, 67, 0.6);
  --stroke-light-gray: rgba(0, 0, 0, 0.1);
  --highlight-light-gray: rgba(118, 118, 128, 0.08);
  --alpha-30-black: rgba(0, 0, 0, 0.3);
  --color-gray: #1772ad;
  --alpha-70: rgba(0, 0, 0, 0.7);
  --highlight-lightest-gray: rgba(118, 118, 128, 0.04);
  --color-black: #000000;
  --alpha-60-black: rgba(0, 0, 0, 0.6);
  --color-dark-gray: #5e636e;
  --web-light-gray-hover-dim: #c5d1db;
}
