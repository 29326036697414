@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin inter_medium_14 {
  font-family: "inter_medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--color-dark-gray);
}

.WebTitle-56 {
  font-family: "inter_bold";
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -2px;
}
.WebSubtitle-44 {
  font-family: "inter_bold";
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -1px;
}
.HeadingH1 {
  font-family: "inter_bold";
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -1px;
}
.HeadingH4 {
  font-family: "inter_bold";
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -1px;
}
.HeadingH2 {
  font-family: "inter_bold";
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -1px;
}
.HeadingH3 {
  font-family: "inter_bold";
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -1px;
}
.HeadingH3-Light {
  font-family: "inter_regular";
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -1px;
}
.HeadingH3-Light-Web-Paragraph {
  font-family: "inter_regular";
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: -1px;
}
.ParagraphLarge-21pt-Bold {
  font-family: "inter_bold";
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.ParagraphLarge-21pt {
  font-family: "inter_regular";
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.ParagraphBody-17pt-Reg {
  font-family: "inter_regular";
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
}
.Body-16ptBold {
  font-family: "inter_bold";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.Body-16ptMedium {
  font-family: "inter_medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.Body-16ptRegular {
  font-family: "inter_regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.Subtitle-15ptSubtitle-Bold {
  font-family: "inter_bold";
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
.Subtitle-15ptSubtitle {
  font-family: "inter_bold";
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
.Footer-14ptFooter {
  font-family: "inter_regular";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}
.Footer-14ptFooter-Thin {
  font-family: "inter_regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}
.Pill-13ptPill-Caps {
  font-family: "inter_medium";
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}
.WebTooltip-12pt {
  font-family: "inter_regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
}
.Mini-Filter-Badge-11pt {
  font-family: "inter_medium";
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
}

a {
  color: var(--color-gray);
  &:hover {
    text-decoration: none;
    color: var(--color-dark-gray);
  }
}

.notification_toast_container {
  position: fixed;
  width: 500px;
  color: #fff;
  padding: 10px 13px;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 var(--stroke-light-gray);
  border: solid 0.5px var(--stroke-light-gray);
  z-index: 9;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 13px;
  left: 50%;
  margin-left: -250px;
  span {
    font-family: "inter_regular";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.5px;
    color: var(--color-white);
  }
  a {
    background: #fff;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    font-family: "inter_medium";
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--color-black);
    padding: 8px 12px;
    display: inline-block;
    margin-left: 15px;
    min-width: 130px;
  }
  &:hover {
    opacity: 1;
  }

  &.alert {
    background: #c3281b;
  }
  &.warning {
    background: #c5b800;
  }
}

.reg_card_modal_close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 9;
}
.modalHotelLogo {
  margin: 0 0 40px;
}

.editor-default-style {
  font-family: "inter_medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-black);
}

.date_time_wrapper {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-bottom: 15px;
  padding: 10px 20px;
  background-color: var(--web-bg);
  border-radius: 10px;
  .date_time {
    color: var(--color-dark-gray);
    font-size: 13px;
  }
  .date_quantity {
    font-size: 14px;
    font-family: "inter_bold";
  }
}

.modal_body_confirm {
  max-height: 60vh;
  overflow-y: auto;
}

.Collapsible__trigger.is-open > div:after {
  content: "";
  background-image: url("../images/downcarat.svg");
  background-size: 30px;
  background-position: right center;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  // transition: all 0.2s ease-in;
  transform: rotate(180deg);
}

.Collapsible__trigger.is-closed > div:after {
  content: "";
  background-image: url("../images/downcarat.svg");
  background-size: 30px;
  background-position: right center;
  background-repeat: no-repeat;
  // transition: all 0.2s ease-in;
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  top: 0;
}

.signup_main_container {
  max-width: 1538px;
  margin: 0 auto;
  padding: 0 20px;
  .signup_steps_container {
    &.edit {
      &:after {
        display: none;
      }
      .signup_steps_content_wrapper {
        border-bottom: 1px solid var(--web-light-gray);
        margin: 56px 0;
        padding-bottom: 11px;
        .tip_container {
          .tip_image {
            display: none;
          }
        }
        .hotel_photo_dropzone {
          margin-bottom: 45px;
        }
      }
    }
  }
.language_support_wrapper {
  position: relative;
  .saveButton {
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--color-blue);
    color: var(--color-white);
    &:disabled {
      color: #b8b8b8;
      background-color: rgba(94, 99, 110, 0.1);
    }
  }
}
  .grey_button {
    font-family: "inter_bold";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #b8b8b8;
    width: 130px;
  height: 23px;
  border: 0;
  border-radius: 8px;
  background-color: rgba(94, 99, 110, 0.1);
  outline: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
  }

  .signup_steps_container:after {
    content: "";
    width: 1px;
    /* height: 100px; */
    display: block;
    background-color: var(--web-light-gray);
    position: absolute;
    top: 150px;
    right: 32.5%;
    bottom: 50px;
  }
  .signup_steps_container_wrapper {
    padding: 32px 0 100px;
    position: relative;
    .languages_tabs_support {
      position: relative;
      .language_select_signup {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 20px;
        min-width: 135px;
        margin-right: 0;
        border-radius: 100px;
        border: solid 0.5px var(--stroke-light-gray);
        .react_select__value-container,
        .react_select__control {
          min-height: 30px;
        }
        .react_select__menu {
          min-width: 200px;
        }
        .react-tabs__tab:focus {
          box-shadow: none;
        }
      }
    }
    .language_support {
      margin-top: 44px;
      .react-tabs__tab {
        border: 0;
        color: var(--color-dark-gray);
        font-family: "inter_medium";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        padding: 9px 12px;
        &:focus {
          box-shadow: none;
        }
      }
      .react-tabs__tab-list {
        border: 0;
        margin: 0 0 44px;
      }
      .react-tabs__tab--selected {
        border-radius: 6px;
        background-color: var(--web-light-gray);
       
        color: var(--color-black);
        padding: 9px 12px;
        border: 0;
      }
    }
    .payment-wrapper {
      margin-bottom: 35px;
      .payment-wrapper-option {
        position: relative;
        margin-bottom: 32px;
      }
      .payment_gateway_options {
        padding-left: 40px;
        &.electronic_way {
          .payment-btns {
            margin: 20px 0 20px;
          }
        }
        .payment-btns {
          margin: 20px 0 40px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .payment_btn_wrapper {
            width: 33.3%;
            &:nth-child(3n + 1) {
              padding-right: 10px;
            }
            &:nth-child(3n) {
              padding-left: 10px;
            }
            button {
              border-radius: 6px;
              border: solid 0.5px var(--stroke-light-gray);
              background-color: var(--color-white);
              width: 100%;
              height: 56px;
              padding: 5px;
              margin-bottom: 10px;
              img {
                max-width: 80%;
                height: auto;
                max-height: 40px;
              }
              &.active,
              &:active,
              &:focus {
                border: solid 2px var(--color-gray);
                outline: none;
              }
            }
          }
        }
      }
    }
    .steps_separator {
      position: absolute;
      top: 50px;
      bottom: 50px;
      .separator_line {
        width: 1px;
        background: #000;
      }
    }
    .exception_content {
      margin-top: 20px;
      border-radius: 8px;
      position: relative;
      border: 1px solid var(--web-light-gray);
      .dropdown_trigger {
        display: flex;
        position: absolute;
        top: 3px;
        right: 40px;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      .close_btn {
        position: absolute;
        top: 5px;
        right: 5px;
        img {
          max-width: 24px;
        }
      }

      .id_exception_wrapper {
        padding: 20px;
        border-top: 1px solid var(--web-light-gray);
        .header_detail_wrapper {
          border-bottom: 0;
        }
      }
      .trigger_wrapper_exception {
        padding: 0 80px 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .country {
          font-family: "inter_medium";
          font-size: 16px;
        }
        .selected_id {
          border-radius: 8px;
          background-color: rgba(23, 114, 173, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--color-gray);
          font-family: "inter_medium";
          font-size: 12px;
          padding: 3px 2px;
          min-width: 36px;
        }
      }
    }
    .header_detail_wrapper {
      display: flex;
      border-bottom: 1px solid var(--web-light-gray);
      .acceptedIDwrapper {
        display: flex;
        align-items: center;
        flex: 1;
        .form_element {
          flex: 1;
        }
      }
      &.header {
        height: 52px;
      }
      &.content {
        height: 44px;
        margin-top: 0;
      }
      &.accepted_content {
        height: 44px;
        margin-top: 0;
        justify-content: space-between;
      }
      .header_detail_content {
        flex: 1;
        display: flex;
        align-items: center;
        position: relative;
        border-right: 1px solid var(--web-light-gray);
        .header_detail_type {
          text-align: left;
        }
        .add_exception {
          position: absolute;
          right: 0;
          top: 12px;
        }
        h4.steps_header {
          font-family: "inter_medium";
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--color-black);
          margin-bottom: 0;
          .MuiInput-underline:before {
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
          }
          .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 0.5px solid var(--color-blue);
          }
        }
        &:last-child {
          border-right: 0;
        }
        &.header_span {
          display: flex;
          justify-content: space-around;
          > span {
            flex: 1;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: "inter_regular";
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: -0.5px;
            color: var(--color-dark-gray);
            &.header_detail_type {
              justify-content: left;
              padding-left: 10px;
            }
            .container_check {
              display: flex;
              align-items: center;
              padding: 0;
              width: 28px;
              height: 28px;
            }
            &:first-child {
              border-right: 1px solid var(--web-light-gray);
            }
          }
          &.mandatory {
            > span:first-child {
              border-right: 0;
            }
          }
        }
      }
      h3.steps_header {
        margin-bottom: 0;
      }
    }
    .extra_para_guest_detail {
      border-radius: 4px;
      border: solid 0.5px var(--highlight-light-gray);
      background-color: var(--highlight-lightest-gray);
      padding: 16px;
      font-family: "inter_medium";
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--color-black);
      margin-bottom: 32px;
      .extra_para_div {
        margin: 20px 0;
        display: flex;
        .extra_para_ul_wrapper {
          flex: 1;
          ul {
            list-style-type: disc;
            margin: 0;
            padding-left: 16px;
          }
        }
      }
    }
    .help_container {
      position: absolute;
      bottom: 100px;
      width: 33.3%;
      z-index: 0;
      right: 0;
      .tip_container {
        margin-left: 30px;
      }
      .tip_title {
        font-family: "inter_bold";
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: var(--color-gray);
      }
      p {
        margin: 10px 0 0;
        @extend .Subtitle-15ptSubtitle;
        font-family: "inter_regular";
        color: var(--alpha-60-black);
        a {
          color: var(--color-blue);
        }
      }
    }
  }
  .continue-btn {
    .formSubmitButton {
      max-width: 350px;
      button {
        width: 100%;
      }
    }
  }
}
.password_signup {
  flex: 1;
}

.signup_header {
  @include flex-end;
}
.sign-primary {
  border-radius: 50px;
  background-color: var(--web-light-gray);
  @include inter_medium_14;
  padding: 0;
  border: 0;
  box-shadow: none;
  a {
    color: var(--color-dark-gray);
    padding: 9px 26px;
    display: inline-block;
  }
  &:focus,
  &:active,
  &:visited,
  &:hover {
    border: 0;
    box-shadow: none;
    outline: none;
    background-color: var(--color-gray);
    a {
      color: var(--color-white);
    }
    color: var(--color-white);
  }
}
.country_phone_wrapper {
  position: relative;
  .floating-input {
    padding-left: 120px;
  }
  .country_phone {
    position: absolute;
    top: 0;
    min-width: 120px;
    margin-right: 0;
  }
}
.error-text,
.errorMessage {
  color: #c3281b;
  font-size: 14px;
  margin-top: 5px;
}

.csv_container_wrapper {
  border-radius: 6px;
  border: dashed 0.5px var(--color-dark-gray);
  padding: 28px 12px 0;
  text-align: center;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .file-icon-csv {
    margin-bottom: 16px;
  }
  .dragdrop {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-dark-gray);
    font-family: "inter_bold";
    margin-bottom: 8px;
  }
  .csv_text,
  .formats {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--alpha-30-black);
    margin-bottom: 20px;
  }
  button.themeButton {
    margin: 0 auto;
    padding: 9px 12px;
    width: auto;
    height: 46px;
    border-radius: 6px;
    font-size: 14px;
    background-color: var(--color-gray);
    min-width: 280px;
  }
  .brand_images {
    img {
      margin: 0 auto;
      max-width: 200px;
    }
  }
}

.brand_container_wrapper {
  border-radius: 6px;
  border: dashed 0.5px var(--color-dark-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 200px;
  .dragdrop {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--color-dark-gray);
    span {
      font-family: "inter_bold";
    }
  }
  .brand_or,
  .formats {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.5px;
    color: var(--alpha-30-black);
    margin: 4px 0 12px;
  }
  button.themeButton {
    margin: 0 auto;
    padding: 9px 12px;
    width: auto;
    height: auto;
    border-radius: 6px;
    font-size: 14px;
    background-color: var(--color-gray);
  }
  .dropzone:focus {
    outline: none !important;
    box-shadow: none;
  }
  .brand_images {
    img {
      margin: 0 auto;
    }
    &.images {
      > div > div {
        max-width: 170px;
        max-height: 170px;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
  .formats {
    margin-top: 16px;
    margin-bottom: 0;
  }
}
.country_team {
  width: 100%;
  .react_select__control {
    border: 0;
    background: none;
    &.react_select__control--is-focused {
      border: 0;
      box-shadow: none;
    }
    .react_select__indicator-separator {
      width: 0;
    }
    .react_select__indicator {
      padding: 0;
    }
    .react_select__single-value {
      @include inter_medium_14;
      > div {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
    }
    .react_select__indicators {
      display: none;
    }
    .react_select__value-container {
      cursor: pointer;
    }
  }
  .react_select__option {
    @include inter_medium_14;
  }
}
.type_select {
  width: 100%;
  .react_select__control {
    border: 0;
    background: none;
    &.react_select__control--is-focused {
      border: 0;
      box-shadow: none;
    }
    .react_select__indicator-separator {
      width: 0;
    }
    .react_select__indicator {
      padding: 0;
    }
    .react_select__single-value {
      @include inter_medium_14;
      > div {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
    }
    .react_select__indicators  {
      padding-right: 10px;
    }
     .react_select__value-container {
      cursor: pointer;
    }
  }
  .react_select__option {
    @include inter_medium_14;
  }
}
.language_select_signup,
.country_phone {
  min-width: 100px;
  margin-right: 40px;
  position: relative;
  &::after {
    content: url(../images/down.svg);
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 4px;
  }
  .react_select__control {
    border: 0;
    background: none;
    &.react_select__control--is-focused {
      border: 0;
      box-shadow: none;
    }
    .react_select__indicator-separator {
      width: 0;
    }
    .react_select__indicator {
      padding: 0;
    }
    .react_select__single-value {
      @include inter_medium_14;
      > div {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
    }
    .react_select__indicators {
      display: none;
    }
    .react_select__value-container {
      cursor: pointer;
    }
  }
  .react_select__option {
    @include inter_medium_14;
  }
}
.header_logo_container {
  display: flex;
  .signup_header_logo {
    &.headerLogo {
      max-width: 68px;
      display: flex;
      align-items: center;
    }
  }
}

.modal-backdrop {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(236, 241, 245, 0.7);
  &.show {
    opacity: 1;
  }
}

.signup_modal {
  .modal-content {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
  .modal-lg {
    max-width: 90%;
  }
  .signup_modal_wrapper {
    max-width: 100%;
    width: 1170px;
    margin: 0 auto;
  }
  .signup_modal_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 65vh;
    overflow-y: auto;
    .signup_modal_steps {
      display: flex;
      width: calc(50% - 4px);
      align-items: center;
      padding: 24px 16px;
      border-radius: 12px;
      margin-bottom: 8px;
      background-color: var(--color-white);
      .step_no {
        width: 36px;
        height: 36px;
        min-width: 36px;
        border-radius: 50%;
        border: solid 0.5px var(--stroke-light-gray);
        background-color: var(--color-white);
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--color-black);
        font-family: "inter_bold";
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
      }
      p {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--color-black);
        font-family: "inter_bold";
      }
      ul {
        list-style-type: disc;
        padding-left: 16px;
        li {
          font-family: "inter_regular";
          font-size: 15px;
          line-height: 1.2;
          color: var(--color-black);
        }
      }
    }
  }
  .signup_header_modal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    h1 {
      font-family: "inter_bold";
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: -1px;
      color: var(--color-black);
    }
    h3 {
      font-family: "inter_regular";
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: -1px;
      color: var(--alpha-70);
      margin-top: 4px;
    }
  }
  .signup_modal_close {
    background: rgba(118, 118, 128, 0.12);
    height: 30px;
    width: 30px;
    border-radius: 100%;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.signup_container {
  font-family: "inter_regular";
  @include flex-center;
  width: 100%;
  height: calc(100vh - 84px);
  overflow-y: auto;
  .signup_form {
    max-height: 100%;
    display: flex;
    .signup_process {
      font-family: "inter_regular";
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.65;
      letter-spacing: normal;
      color: var(--color-dark-gray);
      margin-left: 40px;
      padding-top: 20px;
      .signup_process_wrapper {
        padding: 20px 30px;
        width: 100%;
        max-width: 500px;
        background: var(--web-bg);
        border-radius: 16px;
        a {
          margin-top: 12px;
          display: block;
          font-size: 16px;
          font-family: "inter_bold";
          color: var(--color-gray);
          cursor: pointer;
        }
      }
    }
  }
  .signup_form_content {
    max-height: 100%;
    padding: 20px 0;
    h1 {
      font-family: inter_bold;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: -1px;
      color: var(--black-color);
      margin: 0 0 16px 0;
    }
    h3 {
      font-family: "inter_regular";
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: -1px;
      color: var(--alpha-70);
      margin: 0 0 40px 0;
      position: relative;
      a.change_request {
        position: absolute;
        right: 0;
        bottom: 3px;
        cursor: pointer;
        display: inline-block;
        font-family: "inter_medium";
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--color-blue);
      }
    }
    .topup_content {
      max-width: 560px;
      h3 {
        margin: 0 0 24px 0;
      }
      .credit_image {
        margin-right: 20px;
      }
      .credit_text {
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: solid 0.5px var(--highlight-light-gray);
        background-color: var(--highlight-lightest-gray);
        padding: 16px 26px;
        margin-bottom: 24px;
      }
      .credit_content {
        font-family: "inter_regular";
        font-size: 16px;
        color: var(--alpha-60-black);
      }
    }
    .otp_input_signup {
      input {
        width: 75px !important;
        height: 80px;
        border-radius: 6px;
        background-color: var(--web-light-gray);
        border: 0;
        box-shadow: none;
        outline: none;
        margin-right: 8px;
        color: #000;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: -1px;
        font-family: "inter_bold";
        &:focus {
          outline: none;
        }
      }
    }
  }
  .floating-form .floating-input:focus ~ label,
  .floating-form .floating-input:not(:placeholder-shown) ~ label {
    top: -10px;
    margin-bottom: 0;
  }
  .floating-form .floating-input,
  .floating-form .floating-select {
    height: 40px;
  }
  .floating-form .floating-label {
    margin-bottom: 40px;
  }

  .loader_signup {
    margin-top: 32px;
    font-family: "inter_regular";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--color-dark-gray);
    display: flex;
    align-items: center;
    span {
      display: flex;
      margin-left: 16px;
    }
  }
  .privacy_policy {
    font-family: "inter_regular";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--color-dark-gray);
    margin-top: 32px;
    a {
      display: inline-block;
      color: var(--color-blue);
      cursor: pointer;
    }
  }
  .username_signup,
  .email_signup {
    position: relative;
    .check_email {
      position: absolute;
      right: 0;
      bottom: 5px;
      @include flex-center;
    }
    .avaailable_badge_signup {
      position: absolute;
      right: 0;
      bottom: 5px;
      border-radius: 8px;
      background-color: rgba(23, 114, 173, 0.1);
      border-radius: 8px;
      padding: 3px 6px;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      color: #1772ad;
      @include flex-center;
      font-family: "inter_bold";
    }
  }
}
.steps_header.sticky {
  background: var(--web-bg);
  z-index: 9;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  .steps_header_wrapper {
    &.edit {
      a {
        border-bottom-color: var(--web-bg);
      }
    }
  }
}
.team_member_wrapper {
  padding: 20px;
    margin-bottom: 30px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.01);
  &.disabled {
    background: var(--web-light-gray);
    
    input,
    select,
    .thru_react_select .react_select__control {
      pointer-events: none;
    }
  }
}

.add_card_modal,
.pms_modal_fields {
  margin-top: 20px;
  .form_element_wrapper {
    display: flex;
    .form_element {
      flex: 1;
    }
    .width-month {
      max-width: 150px;
    }
    .width-250 {
      max-width: 250px;
    }
  }
  .floating-form .floating-label {
    margin-bottom: 45px;
    label {
      color: var(--alpha-30-black);
      font-family: "inter_medium";
    }
  }
  .floating-form .floating-input:focus ~ label,
  .floating-form .floating-input:not(:placeholder-shown) ~ label {
    color: var(--alpha-60-black);
  }
}

input {
  box-shadow: none;
  outline: none;
}

.steps_header_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1498px;
  margin: 0 auto;
  &.edit {
    border-bottom: 1px solid var(--web-light-gray);
    a {
      flex: none;
      padding: 16px 0 16px 0;
      margin-right: 30px;
      border: 0;
      min-height: 0;
      background: transparent;
      cursor: pointer;
      font-family: "inter_regular";
      border-bottom: 2px solid #fff;
      border-radius: 0;
      &.active {
        border-bottom-color: #000;
      }
      &:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:last-child {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  a {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--web-bg);
    min-height: 88px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-dark-gray);
    border-right: 1px solid var(--web-light-gray);
    &:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    &:last-child {
      border-right: 0;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    span {
      opacity: 0.5;
    }
    &.active {
      span {
        color: var(--black-color);
        opacity: 1;
      }
    }
    &.complete {
      span.steps_num {
        background: var(--web-light-gray);
        opacity: 1;
      }
      .steps_number {
        display: none;
      }
      .complete_image {
        display: block;
      }
    }
    span.steps_num {
      width: 36px;
      height: 36px;
      min-width: 36px;
      border-radius: 50%;
      background-color: #fff;
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "inter_bold";
      margin-right: 16px;
    }
    .complete_image {
      display: none;
    }
  }
}
.moreFilterActionButton {
  .btn-primary.dropdown-toggle {
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    background: transparent;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    padding: 0;
    position: relative;
    outline: none;
    box-shadow: none;
    margin: 3px 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    &:after {
      display: inline-block;
      margin-left: 0;
      vertical-align: 0;
      content: "";
      border-top: none;
      border-right: none;
      border-bottom: 0;
      border-left: none;
      background-image: url("../images/more.png");
      width: 16px;
      height: 4px;
      background-position: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-repeat: no-repeat;
    }
  }
  .show {
    .btn-primary.dropdown-toggle {
      background: #ecf1f5;
      border: 0.5px solid transparent;
    }
  }
}
.topup_recharge_wrapper {
  border-radius: 16px;
  background-color: var(--web-bg);
  padding: 56px;
  margin-bottom: 56px;
  .reservation_account {
    font-family: "inter_bold";
    font-size: 56px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -2px;
    color: var(--color-black);
  }
  .reservation_remaining {
    font-family: "inter_semibold";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--color-black);
    margin-top: 12px;
  }
  .auto_recharge {
    font-family: "inter_regular";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--color-dark-gray);
    .themeColor {
      color: var(--color-gray);
    }
  }
  .topup_btns {
    margin-top: 35px;
    button {
      &.themeButton {
        margin-right: 24px;
      }
    }
  }
}

.editbtn {
  border-radius: 8px;
  background-color: rgba(94, 99, 110, 0.1);
  position: absolute;
  right: 0;
  padding: 2px 15px;
  font-size: 14px;
  color: var(--alpha-30-black);
  font-family: "inter_bold";
  outline: none;
  border: 0;
}

.signup_steps_content_wrapper {
  margin: 32px 0 23px;
  .custom_field_wrapper{
    position: relative;
    .custom_field_error_wrapper{
      display: flex;
      .error-text{
        width: 33.3%;
        padding-left: 10px;
      }
    }
    .close_btn{
      position: absolute;
      right: 0;
      top: 6px;
    }
  }
  &.billing_credits {
    + .help_container {
      bottom: 200px;
    }
    .continue-btn {
      margin-top: 30px;
    }
  }
  &.payment_page,
  &.invite_teams {
    min-height: 30vh;
  }
  .help_info {
    position: absolute;
    bottom: 20px;
    font-family: "inter_regular";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--alpha-30-black);
    .primary {
      color: var(--color-gray);
    }
  }
  .setup-recharge-block {
    margin-bottom: 30px;
    .setup_header {
      color: var(--black-color);
      font-size: 16px;
      font-family: "inter_medium";
      display: flex;
      justify-content: space-between;
    }
    .switch_text {
      color: var(--alpha-60-black);
      font-size: 16px;
      font-family: "inter_regular";
      max-width: 80%;
    }
  }

  .m-b-45 {
    margin-bottom: 45px;
  }
  .hotel_photo_dropzone {
    margin-top: 20px;
  }
  .extra_para {
    margin-bottom: 32px;
    @extend .Body-16ptRegular;
    color: var(--alpha-70);
  }
  .floating-form {
    .yes_questions {
      border-radius: 4px;
      border: solid 0.5px var(--highlight-light-gray);
      background-color: var(--highlight-lightest-gray);
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--color-dark-gray);
      font-family: "inter_regular";
      margin: 24px 0 38px;
      padding: 16px;
    }
    .terms_questions {
      font-family: "inter_medium";
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--color-black);
      height: 43px;
      border-bottom: 0.5px solid var(--stroke-light-gray);
      margin-bottom: 38px;
    }
    .form_element_wrapper {
      display: flex;
      position: relative;
      &.top_up_wrapper {
        margin-top: 30px;
      }
      .close_btn {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
      .tax_invoice_label label {
        position: absolute;
        font-family: "inter_regular";
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        top: -10px;
        color: var(--alpha-60-black);
        margin-bottom: 0;
      }
      .width-150 {
        max-width: 250px;
      }
      .form_element {
        flex: 1;
      }
      Textarea {
        width: 100%;
        min-height: 130px;
        border-radius: 6px;
        border: solid 0.5px var(--stroke-light-gray);
        padding: 12px;
        &:focus {
          box-shadow: 0 0 4px 0 rgba(23, 114, 173, 0.5);
          outline: none;
        }
        &::-webkit-input-placeholder {
          /* Edge */
          color: var(--alpha-30-black);
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: var(--alpha-30-black);
        }

        &::placeholder {
          color: var(--alpha-30-black);
        }
      }
    }
    .floating-input:focus ~ label,
    .floating-input:not(:placeholder-shown) ~ label {
      top: -10px;
      @extend .Footer-14ptFooter;
      opacity: 1;
      color: var(--alpha-60-black);
      margin-bottom: 0;
    }
    .floating-input,
    .floating-select {
      height: 40px;
      &::-webkit-input-placeholder {
        /* Edge */
        color: var(--alpha-30-black);
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--alpha-30-black);
      }

      &::placeholder {
        color: var(--alpha-30-black);
      }
    }
    .thru_react_select {
      label {
        color: var(--alpha-60-black);
        font-family: "inter_regular";
      }
    }
    label {
      color: var(--alpha-30-black);
      font-family: "inter_medium";
    }
  }
  h3.steps_header {
    @extend .HeadingH3;
    position: relative;
    margin-bottom: 30px;
    .switch_declaration {
      position: absolute;
      right: 0;
      top: 2px;
    }
  }
  .tip_container {
    margin-top: 50px;
    ul {
      list-style-type: disc;
      padding-left: 16px;
    }
    .tip_image {
      margin-left: 10px;
    }
    .tip_text {
      @extend .Body-16ptRegular;
      color: var(--alpha-60-black);
      padding: 16px;
      border-radius: 4px;
      border: solid 0.5px var(--highlight-light-gray);
      background-color: var(--highlight-lightest-gray);
      margin-top: 7px;
      margin-left: 10px;
      p {
        margin-bottom: 0;
      }
    }
  }
}
.terms_wrapper_editor {
  border-radius: 6px;
  border: solid 0.5px var(--stroke-light-gray);
  margin-bottom: 20px;
  .rdw-editor-toolbar {
    border: 0;
    border-bottom: solid 0.5px var(--stroke-light-gray);
    border-radius: 6px;
  }
  .DraftEditor-editorContainer {
    max-height: 200px;
    min-height: 200px;
    padding: 25px;
  }
}

.thru_react_select {
  position: relative;
  label {
    position: absolute;
    font-family: "inter_regular";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    top: -10px;
    color: var(--alpha-60-black);
    margin-bottom: 0;
  }
  .react_select__control {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-family: "inter_regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    background: none;
    height: 40px;
    &.react_select__control--is-focused {
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: none;
    }
    .react_select__indicator-separator {
      width: 0;
    }
    .react_select__indicator {
      padding: 0;
    }
    .react_select__single-value {
      font-family: "inter_regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
    .react_select__indicators {
      margin-right: 20px;
    }
    .react_select__value-container {
      cursor: pointer;
      padding: 2px 6px;
    }
  }
  &.role_team {
    .react_select__control {
      .react_select__indicators {
        margin-right: 40px;
      }
    }
  }
  .react_select__option {
    @include inter_medium_14;
  }
}

.billing_address_confirm {
  margin-bottom: 20px;
}

/* The container */
.container_check,
.container_radio {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  font-family: "inter_medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 28px;
  margin-bottom: 0;
  letter-spacing: normal;
  color: var(--color-black);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.disabled {
    color: #5e636e;
  }
}

/* Hide the browser's default checkbox */
.container_check input,
.container_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark_check {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background: url("../images/UnCheck2.svg");
}
.container_radio {
  .checkmark_check {
    background: url("../images/radioUncheck2.svg");
    &:after {
      background: url("../images/radio.svg");
    }
  }
}

/* Create the checkmark_check/indicator (hidden when not checked) */
.checkmark_check:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background: url("../images/Check2.svg");
  display: none;
}

/* Show the checkmark_check when checked */
.container_check input:checked ~ .checkmark_check:after,
.container_radio input:checked ~ .checkmark_check:after {
  display: block;
}

.container_check.readonly-check {
  pointer-events: none;
  input ~ .checkmark_check:after {
    background: url("../images/Check3.svg");
  }
}

/* Show the checkmark_check when checked */
.container_check input:checked:disabled ~ .checkmark_check:after {
  display: block;
  background: url("../images/Check3.svg");
}

.container_check input:disabled ~ .checkmark_check:after {
  display: block;
  background: url("../images/Check4.svg");
}

.addmore_btn {
  button {
    border-radius: 4px;
    border: solid 0.5px var(--highlight-light-gray);
    background-color: var(--highlight-lightest-gray);
    padding: 13px 16px;
    font-family: "inter_regular";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-gray);
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:focus {
      outline: none;
    }
  }
  margin-bottom: 30px;
  margin-top: -20px;
}

.k-toolbar {
  box-shadow: none;
}
.k-editor {
  border: solid 0.5px var(--stroke-light-gray);
}

.actiondashboardbutton {
  button {
    font-family: "inter_bold";
    border-radius: 6px;
    padding: 8px 14px 8px 36px;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .dashboardButton {
    background-color: var(--web-light-gray);
    color: var(--color-dark-gray);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 16px;
      margin-top: -4px;
    }
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ecf1f5;
    }
  }
}

.data_table_container {
  display: flex;
  padding: 15px 40px;
  flex-wrap: wrap;
  background: #fbfbfb;
  .table_data_wrapper {
    display: flex;

    width: 30%;
    min-width: 30%;
    color: var(--color-dark-gray);
    font-size: 12px;
    margin: 5px;
    .data_value {
      margin-left: 10px;
      font-family: "inter_bold";
    }
  }
}
.language_select_wrapper {
  display: flex;
  flex-wrap: wrap;
  max-height: 60vh;
  overflow-y: auto;
  .languageIDwrapper {
    min-width: 33.3%;
    margin-bottom: 20px;
  }
}
.secondaryBtn {
  font-family: "inter_medium";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-dark-gray);
  border-radius: 6px;
  background: #fff;
  margin-right: 24px;
  padding: 13px 30px;
  height: 46px;
  border: 0.5px solid rgba(0, 0, 0, 0.05);
  &.small {
    width: 80px;
    height: 30px;
    font-size: 14px;
    padding: 0;
  }
  &:hover,
  &:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ecf1f5;
    outline: none;
  }
}

.upload_csv_wrapper {
  h2.add_reservation {
    font-family: "inter_bold";
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -1px;
    color: var(--color-black);
    margin-bottom: 20px;
  }
  .help_text {
    color: #adadad;
    font-size: 13px;
    font-style: italic;
  }
  .delete-icon {
    font-size: 18px;
    color: #adadad;
  }
  .datatable_csv {
    .rdt_Table {
      border-radius: 8px;
      border: 0.5px solid rgba(0, 0, 0, 0.05);
    }
    .rdt_TableRow,
    .rdt_ExpanderRow {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
    }
    .rdt_TableCell,
    .rdt_TableCol {
      border-right: 0.5px solid rgba(0, 0, 0, 0.05);
      background-color: transparent;
      &:last-child {
        border-right: 0;
      }
    }
    .rdt_TableHeadRow {
      min-height: 40px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
      background-color: var(--web-bg);
    }
    .dgbNFW {
      background-color: transparent;
    }
  }
  .submitCSV {
    margin-top: 30px;
    button {
      min-width: 350px;
    }
  }
}

.gerneral_modal {
  .modal-body {
    padding: 0;
  }
}

.privacy_policy_container {
  font-size: 13px;
  line-height: 23px;
  font-family: "inter_regular";
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  max-height: 60vh;
  overflow-y: auto;
  padding: 0 25px 0;
  margin-bottom: 0;
}
.privacy_policy_container .bullets ul {
  list-style-type: disc;
  padding-left: 20px;
}
.privacy_policy_container .bullets ul li {
  font-size: 13px;
  line-height: 23px;
  font-family: "inter_regular";
}
.privacy_policy_container a {
  color: #1772ad;
}
.privacy_policy_container > ul {
  list-style-type: decimal;
  font-size: 15px;
}
.privacy_policy_container > ul ul {
  list-style-type: lower-alpha;
}
.privacy_policy_container h1 {
  font-family: "inter_bold";
  font-size: 28px;
  letter-spacing: -2px;
  margin: 36px 0 88px;
}
.privacy_policy_container h2 {
  font-size: 17px;
  line-height: 25px;
  font-family: "inter_bold";
  margin: 30px 0 24px;
}
.privacy_policy_container h3 {
  font-size: 15px;
  line-height: 25px;
  font-family: "inter_bold";
  margin: 24px 0 12px;
}
.privacy_policy_container ul li {
  font-size: 28px;
  line-height: 30px;
  font-family: "inter_bold";
}
.privacy_policy_container ul li div,
.privacy_policy_container ul li p,
.privacy_policy_container > ul ul li {
  font-family: "inter_regular";
  font-size: 13px;
  line-height: 23px;
}

.saas_agreement.privacy_policy_container ul li {
  font-family: "inter_regular";
  font-size: 13px;
  line-height: 23px;
}

.check_member_buttons {
  button:first-child {
    margin-right: 10px;
  }
}
.verify_wrapper {
  position: absolute;
  right: 0;
  top: 5px;
  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--web-light-gray);
    padding: 2px 7px;
    font-size: 12px;
    border-radius: 5px;
    font-family: "inter_bold";
    &.verified {
      background-color: rgba(9, 209, 77, 0.3);
      color: #007928;
    }
    &.not_verified {
      background-color: rgba(195, 40, 27, 0.1);
      color: #c3281b;
      margin-right: 40px;
    }
  }
}

// Loader css
@keyframes ldio-qs451jwlpr {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-qs451jwlpr div {
  left: 47.5px;
  top: 16.5px;
  position: absolute;
  animation: ldio-qs451jwlpr linear 0.9345794392523364s infinite;
  background: #636363;
  width: 5px;
  height: 19px;
  border-radius: 0px / 0px;
  transform-origin: 2.5px 33.5px;
}
.ldio-qs451jwlpr div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.8678237650200267s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(2) {
  transform: rotate(25.714285714285715deg);
  animation-delay: -0.8010680907877169s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(3) {
  transform: rotate(51.42857142857143deg);
  animation-delay: -0.7343124165554072s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(4) {
  transform: rotate(77.14285714285714deg);
  animation-delay: -0.6675567423230975s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(5) {
  transform: rotate(102.85714285714286deg);
  animation-delay: -0.6008010680907877s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(6) {
  transform: rotate(128.57142857142858deg);
  animation-delay: -0.5340453938584779s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(7) {
  transform: rotate(154.28571428571428deg);
  animation-delay: -0.4672897196261682s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(8) {
  transform: rotate(180deg);
  animation-delay: -0.40053404539385845s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(9) {
  transform: rotate(205.71428571428572deg);
  animation-delay: -0.33377837116154874s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(10) {
  transform: rotate(231.42857142857142deg);
  animation-delay: -0.26702269692923897s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(11) {
  transform: rotate(257.14285714285717deg);
  animation-delay: -0.20026702269692923s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(12) {
  transform: rotate(282.85714285714283deg);
  animation-delay: -0.13351134846461948s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(13) {
  transform: rotate(308.57142857142856deg);
  animation-delay: -0.06675567423230974s;
  background: #636363;
}
.ldio-qs451jwlpr div:nth-child(14) {
  transform: rotate(334.2857142857143deg);
  animation-delay: 0s;
  background: #636363;
}
.loadingio-spinner-spinner-degwbik4ii {
  width: 31px;
  height: 31px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-qs451jwlpr {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.31);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-qs451jwlpr div {
  box-sizing: content-box;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-spinner div {
  transform-origin: 20px 20px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(126, 125, 125);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--color-gray);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-gray) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.verifyContact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  > div {
    position: relative;
    width: 300px;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 200px;
  }
  h1 {
    position: absolute;
    top: 50%;
    color: rgba(0, 0, 0, 0.7);
    font-family: "inter_bold";
    font-size: 35px;
  }
}

/*  checked css  */

.verifyContact .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.verifyContact .checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.verifyContact .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.no_data_found {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 var(--stroke-light-gray);
  background-color: var(--color-white);
  flex-direction: column;
  h2 {
    font-family: "inter_bold";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-dark-gray);
    margin: 10px 0;
  }
  h4 {
    font-family: "inter_regular";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--alpha-30-black);
  }
}

@media (max-width: 1500px) {
  .signup_main_container {
    max-width: 1161px;
    padding: 0;
  }
  .steps_header_wrapper {
    max-width: 1161px;
  }
}

@media (max-width: 1280px) {
  .signup_main_container {
    max-width: 100%;
    padding: 0 15px;
  }
  .signup_container .signup_form .signup_process .signup_process_wrapper {
    max-width: 300px;
  }
}

@media (max-width: 1024px) {
  .signup_main_container {
    .floating-form .form_element_wrapper .width-150 {
      max-width: 150px;
    }
  }
}

@media (max-device-width: 768px) {
  .steps_header_wrapper {
    max-width: 100%;
    overflow-x: auto;
  }
  .signup_container .signup_form {
    flex-direction: column;
    .signup_process {
      margin-left: 0;
      .signup_process_wrapper {
        max-width: 500px;
      }
    }
  }
  .signup_main_container {
    .floating-form .form_element_wrapper .width-150 {
      max-width: 220px;
    }
    .signup_steps_container_wrapper {
      .tip_container {
        margin-top: 0;
      }
      .help_container {
        position: relative;
        margin-top: 20px;
        bottom: 0;
        margin-bottom: 20px;
        width: 100%;
        .tip_container {
          margin-left: 0;
        }
      }
    }
    .signup_steps_container {
      &:after {
        display: none;
      }
    }
  }
}

.privacy_policy_forgot {
  font-family: "inter_regular";
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-dark-gray);
  margin-top: 32px;
  a {
    display: inline-block;
    color: var(--color-blue);
    cursor: pointer;
  }
}
