@import "library";

@font-face {
  font-family: "inter_regular";
  src: url(../fonts/inter/Inter-Regular-slnt=0.ttf);
}
@font-face {
  font-family: "inter_bold";
  src: url(../fonts/inter/Inter-Bold-slnt=0.ttf);
}
@font-face {
  font-family: "inter_medium";
  src: url(../fonts/inter/Inter-Medium-slnt=0.ttf);
}
@font-face {
  font-family: "inter_semibold";
  src: url(../fonts/inter/Inter-SemiBold-slnt=0.ttf);
}
@font-face {
  font-family: "sf_pro_text_bold";
  src: url(../fonts/SFProText/sf-pro-text-bold.ttf);
}
@font-face {
  font-family: "sf_pro_text_semibold";
  src: url(../fonts/SFProText/SFProText-Semibold.otf);
}

@font-face {
  font-family: Inter;
  src: url(../fonts/inter/Inter-Regular-slnt=0.ttf);
}

body {
  font-family: 'inter_regular';
  overflow-x: hidden;
}
// Login page scss 
.height_100{
  height: 100vh;
}
.width_100{
  width: 100%;
}
.img-responsive{
  max-width: 100%;
}
.pl-0{
  padding-left: 0;
}
.pr-0{
  padding-right: 0;
}
.mr-25{
  margin-right: 25px;
}
.customContainer{
  max-width: 1538px;
  margin: 0 auto;
  padding: 0 20px;
}
.floating-form{ 
.floating-label { 
  position:relative; 
  margin-bottom:45px; 
}
.floating-input , .floating-select {
  padding: 4px 4px;
  display: block;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: "inter_regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.floating-input:focus , .floating-select:focus {
     outline:none;
     border-bottom:2px solid #1772AD; 
}

label {
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  font-family: "inter_regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #5E636E;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

.floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
  top: -18px;
  font-size: 15px;
  line-height: 18px;
  color: #5E636E;
  opacity: 0.8;
}

.floating-select:focus ~ label , .floating-select:not([value=""]):valid ~ label {
  top: -18px;
  font-size: 15px;
  line-height: 18px;
  color: #5E636E;
  opacity: 0.8;
}
.floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
  width:50%;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.floating-textarea {
   min-height: 30px;
   max-height: 260px; 
   overflow:hidden;
  overflow-x: hidden; 
}

}

.forgotPassword{
  display: inline-block;
  vertical-align: top;
  width: 40%;
  // text-align: right;
  text-align: left;
  margin-bottom: 25px;
  a{
    font-family: "inter_regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #1772ad;
    text-decoration: none;
  }
}
.rememberButton{
  width: 60%;
  display: inline-block;
  vertical-align: top;
  .wrapperRadio {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 37px;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: "inter_medium";
    color: #1c1d21;
  }
  .wrapperRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #1772AD;
    border-radius: 50%;
  }
  .wrapperRadio:hover input ~ .checkmark {
    background-color: #1772AD;
  }
  .wrapperRadio input:checked ~ .checkmark {
    background-color: #1772AD;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .wrapperRadio input:checked ~ .checkmark:after {
    display: block;
  }
  .wrapperRadio .checkmark:after {
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ffffff;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}

.themeButton {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  border: none;
  width: 180px;
  height: 46px;
  border-radius: 6px;
  background: #1772AD;
  margin-right: 45px;
  cursor: pointer;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  &.small {
    width: 80px;
    height: 30px;
    font-size: 14px;
  }
  &:disabled {
    background: #bbbbbb;
    pointer-events: none;
    &:hover {
      background: #bbbbbb;
    }
  }
  &:focus{
    outline: none;
  }
  &:hover{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #1772AD;
  }
}
.login_container {
  height: 100vh;
  background-color: #2585c4;
  background-image: url("../images/loginbg.png");
  background-position: center right;
  background-size: cover;
  .loginForm{
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    width: 55%;
    border-radius: 0px 16px 16px 0px;
    padding: 0 10% 0 10%;
    .thru-logo{
      margin-bottom: 44px;
      img{
        max-width: 171px;
      }
    }
    .login_heading{
      h1{
        font-family: "inter_bold";
        margin-top: 0;
        margin-bottom: 32px;
        padding-bottom: 20px;
        font-size: 34px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: -1px;
        color: #1c1d21;
      }
    }
    .signUpAction {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      background: transparent;
      border: none;
      color: #1772ad;
      font-family: "inter_medium";
      cursor: pointer;
      &:focus{
        outline: none;
      }
    }
    .fomrWrapMax{
      max-width: 460px;
      margin: 0 auto;
    }
  }
}


// statistics css start here 
.content-area{
  background-color: #F7F8F9;
  min-height: 100vh;
  overflow-y: auto;
  &.signup-area {
    background: #fff;
  }
}
.content{
  margin-top: 32px;
}
.filtertype ul {
  margin-bottom: 32px;
  padding-left: 0;
  li{
    margin-right: 15px;
    list-style: none;
    font-size: 16px;
    font-family: "inter_bold";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5e636e;
    padding: 8px 24px;
    border-radius: 6px;
    cursor: pointer;
    &:last-child{
      margin-right: 0;
    }
  }
  li.active{
    background-color: #1772ad;
    color: #ffffff;
  }
  
}
.filterDays ul {
  margin-bottom: 32px;
  padding-left: 0;
  li{
    margin-right: 15px;
    list-style: none;
    font-size: 16px;
    font-family: "inter_regular";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5e636e;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    &.orderByFilterDropdown {
      padding-top: 0;
      margin-right: 0;
    }
    &:last-child{
      margin-right: 0;
      padding-right: 0;
      padding-top: 0;
    }
  }
  li.active{
    background-color: #ffffff;
    color: #000000;
  }
  li{
    position: relative;
    .sortingFilter {
      //width: 183px;
      background: #FFFFFF;
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 100px;
      padding: 8px 20px 8px 10px;
      position: relative;
      span.sortLabel {
        margin-right: 8px;
        font-family: "inter_medium";
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: rgba(60, 60, 67, 0.6);
      }
     
      .fa-caret-down{
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: rgba(60, 60, 67, 0.6);  
      }
    }
    span.drlabelValueFirst {
      padding: 3px 6px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.4px;
      font-family: "sf_pro_text_semibold";
    }
    span.drlabelValueFirst.green{
      background: rgba(9, 209, 77, 0.1);
      color: #09D14D;
    }
    span.drlabelValueFirst.Warnings{
      background-color: rgba(195, 40, 27, 0.1);
      color: #C3281B;
    }
  }
  ul.sortingFilterDropDown {
    display: none;
    position: absolute;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    z-index: 9;
    top: 100%;
    min-width: 285px;
    right: 0;
    li {
      color: #000;
      border-bottom: 1px solid rgba(118, 118, 128, 0.08);
      margin: 0;
      border-radius: 0;
      padding: 19px 24px;
      font-size: 15px;
      line-height: 18px;
      font-family: "inter_medium";
      display: block;
      .drlabelValueFirst{
        margin-right: 8px;
      }
      .drlabelValuesecond{
        font-size: 14px;
      }
    }
  }
  ul.sortingFilterDropDown.sortingFilterDropDownOpen{
    display: block;
  }
}
.close-btn-filter {
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
  justify-content: center;
  align-items: center;
}
.statistics{
 .statisticsCol{
  border-radius: 12px;
  background-color: #ffffff;
  padding: 22px 33px;
  position: relative;
  &.inactive {
    background-color: var(--web-light-gray);
    pointer-events: none;
  }
  &.active {
    .close-btn-filter {
      display: flex;
    }
    p.count, p.label {
      color: #fff;
    }
    &.arriving_today {
      background-color: #5e636e;
      p.arriving_today {
        color: #fff;
      }
    }
    &.completed {
      background-color: #09d14d;
      p.completed {
        color: #fff;
      }
    }
    &.in_progress {
      background-color: #ff6600;
      p.in_progress {
        color: #fff;
      }
    }
    &.with_alert {
      background-color: #c3281b;
      p.with_alert {
        color: #fff;
      }
    }
    &.pending {
      background-color: #b8b8b8;
      p.pending {
        color: #fff;
      }
    }
  }
  p{
    margin-bottom: 0;
  }
  p.count{
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -1px;
    color: #000000;
    font-family: "inter_bold";
  }
  p.label{
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    margin-top: 2px;
    letter-spacing: -0.4px;
    font-family: "inter_bold";
  }
  p.label.arriving_today {
    color: #5e636e;
  }
  p.label.completed {
    color: #09d14d;
  }
  p.label.in_progress {
    color: #ff6600;
  }
  p.label.with_alert {
    color: #c3281b;
  }
  p.label.pending {
    color: #b8b8b8;
  }
 }
}

.guestStatus {
  display: flex;
  .message_status {
    display: flex;
  }
  p{
    margin-right: 8px;
    margin-bottom: 0;
    border-radius: 8px;
    padding: 3px 6px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;         
    font-family: "sf_pro_text_semibold";
    vertical-align: middle;
    &:last-child{
      margin-right: 0;
    }
    img{
      max-width: 14px;
      max-height: 14px;
    }
  }
  p.pending{
    background-color: rgba(184, 184, 184, 0.1);
    color: #B8B8B8;         
  }
  p.completed{
    background-color: rgba(9, 209, 77, 0.1);  
    color: #09D14D;        
  }
  p.alert{
    background-color: rgba(195, 40, 27, 0.1);  
    color: #C3281B;
  }
  p.profile{
    background-color: rgba(255, 102, 0, 0.1);
    color: #FF6600;
  }
}
.guestDetailBoxRow > div {
  margin-bottom: 30px;
}
.guestDetailBoxRow {
  margin-top: 32px;
  .guestDetailBox {
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    background-color: #fff;    
    height: 325px;
    .wrapperDetailShow{
      padding: 28px;
    }
    .guestInfo{
      margin-bottom: 26px;
      .guestName{
        font-family: "inter_bold";
        font-style: normal;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -1px;
        color: #000000;
        margin-top: 0;
        margin-bottom: 4px;
      }
    }
    .countryFlag{
      img,.flagLib{
        max-width: 30px;
        max-height: 30px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        object-fit: cover;
        object-position: center;
        border: 0.5px solid rgba(0, 0, 0, 0.1);
      }
    }
    .reservationLabel, .guestNationalityLabel, .guestRoomNumberLabel {
      font-family: "inter_regular";
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #5E636E;
      opacity: 0.8;
      margin: 0 0 3px 0;
    }
    .reservationNumber, .guestNationality p, .guestRoomNumber p{
      font-family: "inter_regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 0;
    }
    .guestReservationDetail {
      margin-bottom: 17px;
    }
    .guestNationality{
      margin-bottom: 0px;
    }
    .actionbutton {
      margin-right: 12px;
      button {
        font-family: "inter_medium";
        border-radius: 6px;
        padding: 12px 10px;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        border: none;
        outline: none;
        cursor: pointer;
      }
      .checkinButton{
        background-color: #1772ad;
        color: #ffffff;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        &:hover{
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #1772AD;
        }
      }
      .showIdButton, .inviteButton{
        background-color: #ecf1f5;
        color: #5e636e;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        &:hover{
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ECF1F5;
        }
      }
    }
    .moreActionButton {
      .btn-primary.dropdown-toggle {
        border: 0.5px solid rgba(0, 0, 0, 0.1);
        background: transparent;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        padding: 0;
        position: relative;
        outline: none;
        box-shadow: none;
        margin: 0;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        &:after {
          display: inline-block;
          margin-left: 0;
          vertical-align: 0;
          content: "";
          border-top: none;
          border-right: none;
          border-bottom: 0;
          border-left: none;
          background-image: url("../images/more.png");
          width: 16px;
          height: 4px;
          background-position: center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-repeat: no-repeat;
        }
      }
      .show{
        .btn-primary.dropdown-toggle{
          background: #ecf1f5;
          border: 0.5px solid transparent;
        }
      }
     
    }
    
    .guestDetailAction{
      position: absolute;
      bottom: 28px;
      left: 0;
      padding: 0 22px 0 43px;
      width: 100%;
      right: 0;
    }
  }
}

.tooltip-inner {
  max-width: 300px;
  text-align: left;
  background: rgb(0, 0, 0);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 8px 13px;
  p{
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.5px;
    font-family: "inter_medium";
    margin-bottom: 0;
  }
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  border-top-color: rgb(0, 0, 0);
}
ul.tooltipPointsListing {
  padding-left: 0;
  text-align: left;
  padding: 0px 28px 0px 15px;
  margin-bottom: 0;
  position: relative;
  counter-reset: list;
  li{
    list-style: none;
    position: relative;
    padding: 2px 0;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.5px;
    font-family: "inter_medium"; 
    counter-increment: list; 
    &:before{
      content: counter(list) ".";
      position: absolute;
      left: -14px;
      font-family: "inter_bold";
    }
  }
  li.completePoint{
    opacity: 0.6;
    &:after{
      content: "";
      position: absolute;
      right: -28px;
      width: 18px;
      height: 18px;
      background-image: url("../images/tooltip_right.png");
      background-size: cover;
      background-position: center;
    }
  }
}
.dropdown-menu{
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: none;
  padding: 0;
  min-width: 285px;
  right: 0 !important;
  left: auto !important;  
  margin: 45px 0 !important;
  transform: none !important;
  .section-header {
    a {
      padding: 10px 10px 10px 40px;  
      font-size: 13px;
    }
    &:last-child {
      .dropdown-item {
        &:last-child{
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
  .section-header-title {
    background: #efefef;
    opacity: .7;
    padding: 10px 20px;
    font-size: 12px;
    color: var(--color-dark-gray);
  }
  a {
    color: #000;
    border-bottom: 1px solid rgba(118, 118, 128, 0.08);
    margin: 0;
    border-radius: 0;
    padding: 19px 24px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    &.support_portal {
      display: block;
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      i {
        min-width: 20px;
        margin-right: 10px;
      }
      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #1772AD;
        color: #fff;
      }
    }
    &.logout_link {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      i {
        min-width: 20px;
        margin-right: 10px;
      }
    }
  }
  .dropdown-item{
    font-family: "inter_medium";
    font-size: 15px;
    line-height: 18px;
    // &:last-child{
    //   border-bottom-left-radius: 8px;
    //   border-bottom-right-radius: 8px;
    // }
    &:first-child{
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:hover{
      color: #ffffff;
      text-decoration: none;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #1772AD;
    }
    &:focus{
      color: #ffffff;
      text-decoration: none;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #1772AD;
    }
  }
}

.modal-content {
  border: none;
  outline: 0;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.modal-dialog.modal-md.modal-dialog-centered{
  max-width: 558px;
  width: 558px;
}

.modal-header{
  padding: 33px 28px 28px 28px;
  border-bottom: none;
  .modal-title{
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -1px;
    color: #000000;
    font-family: "inter_bold";
  }
}
.modalPopupClose {
  background: rgba(118, 118, 128, 0.12);
  height: 30px;
  width: 30px;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  img{
    margin: 8px 0;
    max-width: 100%;
  }
}
.modalimageid{
  margin: 0 auto;
  text-align: center;
  img{
    max-width: 100%;
    margin: 0 auto;
  }
  
}
.modal-body {
  padding: 0px 28px 28px 28px;
  .inviteModalData {
    margin-top: 20px;
  }
  .floating-form .floating-label {
    margin-bottom: 35px;
  }
  .ortext{
    p {
      margin: 0 0 35px 0;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #5e636e;
      opacity: 0.8;
    }
  }
}
.modal-footer {
  height: 70px;
  background-color: #f7f8f9;
  border-top: none;
  padding: 0 28px;
  button{
    margin-right: 0;
  }
}

// CheckIn detail View css start here
.detailPanel{
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  min-height: calc(100vh - 150px);
  padding: 31px 28px;
  margin-bottom: 35px;
  &.detailPanelAccomp {
    min-height: auto;
  }
  h1.memberName {
    font-size: 28px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -1px;
    color: #000;
    font-family: "inter_bold";
    margin: 4px 0;
  }
  .memberProfile > div {
    display: inline-block !important;
    vertical-align: inherit;
    &:first-child{
      min-width: 183px;
    }
  }
}
button.themeBackButton {
  border-radius: 6px;
  background-color: #ecf1f5;
  border: none;
  width: 95px;
  height: 36px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5e636e;
  font-family: "inter_bold";
  text-align: center;
  outline: none;
  img {
    margin-right: 10px;
    vertical-align: baseline;
 }
 .memeberDetail {
    margin-top: 32px;
 }
}
.reservationDetail {
  .reservationDetailWrapper{
    margin-top: 32px;
    .signature_image {
      // display: flex;
      // padding: 25px;
      // border: 1px solid var(--web-light-gray);
      // justify-content: center;
      // align-items: center;
    }
    .covid_declaration,
    .health_declaration {
      ul {
        list-style-type: disc;
        padding-left: 20px;
        font-size: 13px;
        line-height: 20px;
      }
      .question_ans {
        font-family: "inter_bold";
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
  h2.memeberDetailLabel {
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -1px;
    color: #1772AD;
    font-family: "inter_bold";
    margin-bottom: 20px;
    margin-top: 0;
  }
  h2.guestDetailLabel {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-dark-gray);
    font-family: "inter_bold";
    margin-bottom: 20px;
    margin-top: 0;
  }
  .memberfieldsData{
    position: relative;
    .eyeImage {
      position: absolute;
      right: 0;
      top: 6px;
      display: inline-block;
      cursor: pointer;
    }
    label{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      font-family: "inter_medium";
      margin-bottom: 0;
    }
  }
  .memberDetail_fields_border {
    background: rgba(0, 0, 0, 0.1);
    height: 1px;
    margin-top: 16px;
    margin-bottom: 20px;
  }
}
.reservationDetailActions {
  margin-top: 32px;
  .DetailActionsButtons{
    button {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5e636e;
      height: 46px;
      border-radius: 6px;
      border: solid 0.5px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      width: 100%;
      display: block;
      margin-bottom: 12px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
    }
    .activebutton{
      background-color: #1772ad;
      color: #ffffff;
    }
    .disabledbutton {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .DetailActionsButtonsBorder {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 28px;
    margin-bottom: 28px;
  }
}
.badgeCount {
  p {
    margin-bottom: 0;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #c3281b;
    font-family: "sf_pro_text_bold";
    border-radius: 8px;
    background-color: rgba(195, 40, 27, 0.1);
    text-align: center;
    width: auto;
    height: 23px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    padding: 0 6px;
  }
}
.documentStatusText {
  p {
    margin: 0;
    height: 50px;
    border-radius: 4px;
    border: solid 0.5px rgba(118, 118, 128, 0.04);
    background-color: rgba(118, 118, 128, 0.04);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: rgba(0,0,0,0.6);
    text-align: center;
    padding: 14px 0;
    margin-top: 8px;
  }
}
.documentPhoto{
  img{
    max-width: 100%;
  }
}
.buttons_wrapper {
  display: flex;
  justify-content: flex-end;
  .pushToPMSButton {
    margin-right: 10px;
    .showIdButton {
      color: var(--color-gray);
    }
  }
}
.accompIDButton, .pushToPMSButton {
  .showIdButton {
    font-family: "inter_medium";
    border-radius: 6px;
    padding: 12px 10px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #ecf1f5;
    color: #5e636e;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    &:hover{
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ECF1F5;
    }
  }
}
.pushToPMSButton {
  display: inline-block;
}

.floating-label textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  resize: none;
  width: 100%;
  padding: 12px;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  font-family: "inter_medium";
  border-radius: 6px;
  &:focus{
    border-color: #1772AD;
  }
}
.modalForm {
  .floating-label {
    textarea {
      height: 251px;
    }
  }
}
.modalalertImg{
  margin-right: 8px;
  max-width: 24px;
}
button.themeButton.withalert {
  background: #C3281B;
  &:hover{
    background: rgba(195, 40, 27, 0.9);
  }
  &:focus{
    background: rgba(195, 40, 27, 0.9);
  }
}

.modalHotelLogo{
  img{
    max-width: 102px;
  }
}
.customModal .modal-dialog {
  width: 612px;
}
.regCardModalUserInfo{
  h1{
    font-size: 19px;
    font-family: "inter_semibold";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.75px;
    color: #000000;
    margin-bottom: 4px;
  }
  h3{
    font-size: 11px;
    font-family: "inter_medium";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);
  }
}
.regCardModalBody {
  // max-height: 60vh;
  overflow-y: auto;
  padding: 0 20px;
}
.printregCardModal {
  .reservationDetail h2.memeberDetailLabel{
    color: var(--color-gray);
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  .reservationDetail .memberfieldsData label{
    margin-bottom: 0;
    font-size: 10.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);
  }
  .reservationDetail .memberfieldsData p {
    font-family: "inter_semibold";
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
  }
  .reservationDetail .memberDetail_fields_border {
    margin-top: 7px;
    margin-bottom: 7px;
    background: none;
  }
  .thruLogoFooter {
    float: right;
    img {
      max-width: 52px;
      margin-top: 6px;
    }
  }
  .regFooterModal {
    margin-top: 38px;
  }
}

.userInfoIcon {
  .btn-primary.dropdown-toggle {
    background-color: transparent;
    height: 36px;
    width: 36px;
    outline: none !important;
    border: none !important;
    position: relative;
    &:focus{
      box-shadow: none !important;
      background-color: transparent;
    }
    &:hover{
      box-shadow: none !important;
      background-color: transparent;
    }
    &:active{
      box-shadow: none !important;
      background-color: transparent;
    }
  }
  .dropdown-menu{
    transform: none !important;
  }
   .btn-primary.dropdown-toggle:after {
    display: inline-block;
    margin-left: 0;
    vertical-align: 0;
    content: "";
    border-top: none;
    border-right: none;
    border-bottom: 0;
    border-left: none;
    background-image: url("../images/user.png");
    width: 16px;
    height: 16px;
    background-position: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
  }
}

.stepBarCsv {
  background: #f7f8f9;
  border-radius: 12px;
  height: 88px;
  margin-bottom: 32px;
  .stepBarCsvRow{
    height: 88px;
  }
  .stepsHeading {
    padding: 0 20px;
    position: relative;
  }
  .stepsHeading:after{
    content: "";
    background-color: #ECF1F5;
    height: 88px;
    top: -26px;
    position: absolute;
    right: 0;
    height: 88px;
    width: 1px;
  }
  .stepBarCsvRow > .col:last-child .stepsHeading:after{
    content: none;
  }
  .stepsHeading > div {
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
  }
  .stepsHeading > div:last-child {
    margin-right: 0;
  }
  .stepsCircle {
    span {
      width: 36px;
      height: 36px;
      border: solid 0.5px rgba(0, 0, 0, 0.1);
      background-color: rgba(255,255,255,0.5);
      display: block;
      text-align: center;
      border-radius: 50%;
      line-height: 34px;
      font-size: 16px;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.5);
      font-family: "inter_bold";
    }
  }

  .activeSteps {
    .stepsCircle {
      span {
        background-color: rgba(255,255,255,1);
        color: rgba(0, 0, 0, 1);
      }
    }
    .stepsText {
      p{
        color: #1772AD;
      }
    }
  }

  .stepsCompleted .stepsCircle {
    span {
      background-color: #ecf1f5;
      color: rgba(0, 0, 0, 1);
      img{
        width: 12px;
      }
    }
  }

  .stepsText {
    p{
      font-family: "inter_bold";
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(94, 99, 110, 0.5);
      margin-bottom: 0;
    }
  }
  
}
.fieldsetHeader{
  h1{
    font-size: 22px;
    font-family: "inter_bold";
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -1px;
    color: #000000;
  }
  h1.bigFont{
    font-size: 34px;
    font-style: normal;
    line-height: 1.21;
    color: #1c1d21;
  }
  h3{
    font-family: "inter_medium";
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 0px;
  }
  p{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    color: #1c1d21;
    margin-bottom: 32px;
  }
}
.simpleTable{
  margin-top: 20px;
  .rdt_Table {
    box-sizing: border-box;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .rdt_TableHeadRow{
    border-color: rgba(0, 0, 0, 0.1);
    background-color: #f7f8f9;
    min-height: 40px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    div{
      font-size: 14px;
      font-family: "inter_medium";
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #1772ad;
      min-width: 100px;
    }
  }
  .rdt_TableBody > div:last-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .rdt_TableRow{
    min-height: 40px;
    div{
      font-family: "inter_regular";
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #000000;
      min-width: 100px;
      span.delete-icon {
        display: inline-block;
        cursor: pointer;
        i {
          color: var(--color-dark-gray);
          &:hover {
              color: var(--alpha-30-black);
          }
        }
      }
    }
  }
  .rdt_Pagination{
    border: none;
  }

}
button.themeButton.mr-16{
  margin-right: 16px;
}
.inactive_themeButton {
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  width: 180px;
  height: 46px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5e636e;
  font-family: "inter_medium";
  margin-right: 16px;
}
.fieldsetFooter {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding-bottom: 36px;
}
.fieldsetFooter.noFixed {
  position: inherit;
  padding-bottom: 0;
  background: transparent;
}

.matchFieldsDataWrapper {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  padding: 28px;
  .matchFieldsData {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
  }
  .matchFieldsDataHeader {
    height: 48px;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
    background-color: #f7f8f9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .systemLabel {
      label {
        font-family: inter_medium;
        font-size: 15px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #000;
        margin: 0;
        padding: 12px;
      }
      &:after{
        content: "";
        background-image: url("../images/arrow.png");
        position: absolute;
        right: 0;
        width: 16px;
        height: 48px;
        background-size: cover;
        background-position: top right;
      }
    }
  }
  .matchFieldsDataContent {
    label {
      height: 40px;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
      background-color: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding: 12px;
    }
  }
  .matchFieldsDataContent > div:last-child{
    label {
      box-shadow: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .matchFieldsDescription{
    .d-inline {
      width: auto;
      display: inline-block !important;
      vertical-align: middle;
      margin-right: 6px;
      &:last-child{
        margin-right: 0;
      }
      .img-responsive{
        max-width: 38px;
      }
    }
   
    p.matchFieldsDescMesg{
      font-family: "inter_bold";
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      color: #000000;
      margin-bottom: 0;
    }
    .matchFieldsDescFieldName{
      background: rgba(23, 114, 173, 0.1);
      border-radius: 8px;
      font-family: "inter_bold";
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #1772AD;
      margin-bottom: 0;
      padding: 4px 10px;
    }
    ul.matchFieldsDescListMesg {
      padding-left: 33px;
      margin-bottom: 16px;
      li {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.6);
        list-style: disc;
     }
    }
    .matchFieldsDescBtns{
      button{
        margin-right: 12px;
        &:last-child{
          margin-right: 0;
        }
      }
      button.confirmMappingBtn {
        height: 36px;
        border-radius: 6px;
        background-color: var(--color-accent);
        font-family: "inter_medium";
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: var(--color-white);
        border: none;
        text-align: center;
        width: 140px;
        outline: none !important;
      }
      button.confirmMappingBtn[disabled]{
        background-color: var(--web-light-gray);
        color: var(--alpha-30-black);
      }
      button.ConfirmedBtn {
        height: 36px;
        border-radius: 6px;
        background-color: var(--color-gray);
        font-family: "inter_medium";
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.4px;
        color: var(--color-white);
        border: none;
        text-align: center;
        width: 118.9px;
        outline: none !important;
      }
      button.ignoreColumnBtn{
        height: 36px;
        border-radius: 6px;
        background-color: var(--web-light-gray);
        font-family: "inter_medium";
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: var(--color-dark-gray);
        border: none;
        text-align: center;
        width: 140px;
        outline: none !important;
      }
      button.errorIgnoreColumnBtn{
        height: 36px;
        border-radius: 6px;
        background-color: var(--web-light-gray);
        font-family: "inter_bold";
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.4px;
        color: #c3281b;
        border: none;
        text-align: center;
        width: 140px;
        outline: none !important;
      }
      button.ignoreColumnBtn[disabled] {
        color: var(--alpha-30-black);
      }
    }
  }

  .matchFieldsDataSelect {
    margin: 5px 15px 5px 0px; 
  }
  .matchFieldsDataSelect > div:first-child {
    background: transparent;
    border: none !important;
    box-shadow: none !important;
  }
  .mfds__control {
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important;
  }
  span.mfds__indicator-separator {
    display: none;
  }
  .mfds__single-value {
    font-family: "inter_bold";
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
  }
}

fieldset.stepTwo .fieldsetMain,
fieldset.stepThree .fieldsetMain {
  padding-bottom: 100px;
  .switch_wrapper_table{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    .switch_wrapper_text{
      margin-left: 10px;
    }
  }
}
.fieldsetData header {
  display: none;
}
.hide{
  display: none !important;
}

.headerDetailWrapperDescription{
  font-size: 15px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
  margin: 12px 0;
  padding-left: 15px;
}

@media (max-width: 1500px) {
  .customContainer{
    max-width: 1161px;
    padding: 0;
  }
}

@media (max-width: 1280px) {
  .customContainer{
    max-width: 100%;
    padding: 0 15px;
  }
  .filtertype ul li {
    font-size: 14px;
    margin-right: 10px;
  }
  .filterDays ul li {
    font-size: 14px;
    margin-right: 10px;
    padding: 8px 4px;
  }
  .guestDetailBoxRow .guestDetailBox .guestDetailAction {
    padding: 0 10px 0 25px;
  }
  .guestDetailBoxRow .guestDetailBox .wrapperDetailShow {
    padding: 15px;
  }
}